import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import monstersAbout from '../assets/images/monsters-about.png';
import wavyMedium from '../assets/icons/wavy-medium.svg';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: "Terms Of Use" }} />
      <main>
        <section id="safe-harbor-introduction">
            <div className="container small">
                <div className="flex wrap between items-center">
                <div className="flex column w-60">
                    <img className="w-60 mb-4" src={wavyMedium} alt="" />
                    <h1 className="grey">Privacy Policy</h1>
                </div>
                {/* <img className="w-40" src={monstersAbout} alt="" /> */}
                </div>
            </div>
        </section>
        <section>
            <div className="container flex column gap-large">
                <div>
                    <p>Last Modified: July 6, 2022</p>
                    <h2 className="grey">Introduction</h2>
                    <p className="medium black mt-5">
                    Boulder Relationship Institute, LLC (<span className="bolder">“Company” or “We”</span>) respect your privacy and are committed to protecting it through our compliance with this policy.
                    This policy describes the types of information we may collect from you or that you may provide when you visit the website <a className="underline italic bold blue" href="https://safeharbor.site">https://safeharbor.site/</a> (our <span className="bolder">“Website“</span>) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
                    This policy applies to information we collect:
                        <ul className="before-black medium">
                            <li>On this Website.</li>
                            <li>In email, text, and other electronic messages between you and this Website.</li>
                        </ul>
                        It does not apply to information collected by:
                        <ul className="before-black medium">
                            <li>Us offline or through any other means, including on any other website operated by Company or any third party (including our affiliates); or</li>
                            <li>Any third party (including our affiliates), including through any application or content (including advertising) that may link to or be accessible from or through the Website.</li>
                        </ul>
                    Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.                
                    </p>
                </div>
                <div>
                    <h2 className="grey">Children Under the Age of 18</h2>
                    <p className="medium black mt-5">
                    Our Website is not intended for children under 18 years of age. No one under the age of 18 may provide any personal information to the Website. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on this Website If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at <a href="mailto:ted@safeharbor.site" className="underline bold blue">ted@safeharbor.site</a>.
                    </p>
                </div>
                <div>
                    <h2 className="grey">Information We Collect About You and How We Collect It</h2>
                    <p className="medium black mt-5">
                    We collect several types of information from and about users of our Website, including information:
                        <ul className="before-black medium">
                            <li>By which you may be personally identified, such as name, postal address, email address, telephone number (<span className="bolder">“personal information“</span>);</li>
                            <li>That is about you but individually does not identify you, and/or;</li>
                            <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
                        </ul>
                    We collect this information:
                        <ul className="before-black medium">
                            <li>Directly from you when you provide it to us.</li>
                            <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies.</li>
                        </ul>
                    <span className="italic medium">Information You Provide to Us</span> 
                    <br></br>The information we collect on or through our Website may include:
                        <ul className="before-black medium">
                            <li>Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website or subscribing to our service. We may also ask you for information when you report a problem with our Website.</li>
                            <li>Records and copies of your correspondence (including email addresses) if you contact us.</li>
                            <li>Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website.</li>
                            <li>Your search queries on the Website.</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h2 className="grey">How We Use Your Information</h2>
                    <p className="medium black mt-5">
                    We use information that we collect about you or that you provide to us, including any personal information:
                    </p>
                    <ul className="before-black medium">
                        <li>To present our Website and its contents to you.</li>
                        <li>To provide you with information, products, or services that you request from us.</li>
                        <li>To fulfill any other purpose for which you provide it.</li>
                        <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                        <li>To notify you about changes to our Website or any products or services we offer or provide through it.</li>
                        <li>To allow you to participate in interactive features on our Website.</li>
                        <li>In any other way we may describe when you provide the information.</li>
                        <li>For any other purpose with your consent.</li>
                    </ul>
                </div>
                <div>
                    <h2 className="grey">Disclosure of Your Information</h2>
                    <p className="medium black mt-5">
                    We may disclose aggregated information about our users without restriction so long as the information that does not identify any individual.
                    <br></br>
                    We may disclose personal information that we collect or you provide as described in this privacy policy:
                        <ul className="before-black medium">
                            <li>To our subsidiaries and affiliates.</li>
                            <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Boulder Relationship Institute, LLC’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Boulder Relationship Institute, LLC’s about our Website users is among the assets transferred.</li>
                            <li>To fulfill the purpose for which you provide it.</li>
                            <li>For any other purpose disclosed by us when you provide the information.</li>
                            <li>With your consent.</li>
                        </ul>
                    We may also disclose your personal information:
                        <ul className="before-black medium">
                            <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                            <li>To enforce or apply our terms of use or terms of sale and other agreements, including for billing and collection purposes.</li>
                            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Boulder Relationship Institute, LLC, our customers, or others.</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h2 className="grey">Choices About How We Use and Disclose Your Information</h2>
                    <p className="medium black mt-5">
                    We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:
                        <ul className="before-black medium">
                            <li><span className="bolder">Tracking Technologies and Advertising.</span> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. [<a className="underline blue bold" target="_blank" href="https://www.adobe.com/devnet/security.html">To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website.</a>] If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
                            <li><span className="bolder">Promotional Offers from the Company.</span> If you do not wish to have your email address/contact information used by the Company to promote our own products or services, you can opt-out by checking the relevant box located on the form on which we collect your data, the order form, or by sending us an email stating your request to <a className="underline blue bold" href="mailto:ted@safeharbor.site">ted@safeharbor.site</a>. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. (This opt out does not apply to information provided to the Company as a result of a product purchase, warranty registration, product service experience or other transactions.)</li>
                        </ul>
                    </p>
                </div>
                <div>
                    <h2 className="grey">Data Security</h2>
                    <p className="medium black mt-5">
                    We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted.
                    <br></br>
                    The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
                    <br></br>
                    Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
                    </p>
                </div>
                <div>
                    <h2 className="grey">Changes to Our Privacy Policy</h2>
                    <p className="medium black mt-5">
                    It is our policy to post any changes we make to our privacy policy on this page [with a notice that the privacy policy has been updated on the Website home page]. If we make material changes to how we treat our users’ personal information, we will notify you through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.
                    </p>
                </div>
                <div>
                    <h2 className="grey">Contact Information</h2>
                    <p className="medium black mt-5">
                    To ask questions or comment about this privacy policy and our privacy practices, contact us at: <a className="underline blue bold" href="mailto:ted@safeharbor.site">ted@safeharbor.site</a>.
                    </p>
                </div>
            </div>
        </section>
      </main>
    </Layout>
  )
}

export default PrivacyPolicy;
